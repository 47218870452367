<template>
  <main
    class="px-4 mb-4 mt-[60px] md:mt-[88px] pt-4 max-lg:max-w-[768px] lg:max-w-[1280px] mx-auto"
  >
    <SiteBlogHeader
      class="bg-white sticky rounded shadow-lg top-[80px] md:top-[104px]"
      :search="searchQuery"
    />
    <SiteBreadcrumbs :items="breadcrumbs" />
    <h1 class="text-xl md:text-2xl lg:text-[2.5rem] mt-4 -mb-3">
      {{ category?.name }}
    </h1>
    <h2 class="font-bold md:text-lg mt-2">
      {{
        category?.name ? $t('blog.categoryArticles') : $t('blog.searchResults')
      }}
    </h2>
    <SiteBlogResults
      :items="['loading', 'initializing'].includes(requestState) ? null : items"
      :page="page"
      :total-pages="
        ['loading', 'initializing'].includes(requestState)
          ? 0
          : Math.ceil((found || 0) / POSTS_PER_PAGE)
      "
      :base-url="
        localePath(
          `/${$t('routes.blog')}` +
            (category?.slug
              ? `/${category?.slug}`
              : searchQuery
              ? `/${searchQuery}-${$t('routes.blogSearch')}`
              : '')
        )
      "
    />
  </main>
</template>

<script setup lang="ts">
import type { PostCard } from '@/types/common';

const SiteBlogHeader = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Header.vue')
);
const SiteBlogResults = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Results.vue')
);
const SiteBreadcrumbs = defineAsyncComponent(
  () => import('@/async-components/Site/Breadcrumbs.vue')
);

interface Category {
  name: string;
  slug: string;
  parent?: {
    name: string;
    slug: string;
  };
}

const POSTS_PER_PAGE = 12;

const props = defineProps<{
  requestState?: 'initializing' | 'loading' | 'error' | 'success';
  found: number;
  items: PostCard[];
  page: number;
  category?: Category;
  searchQuery: string;
  breadcrumbs: { name: string; item: string; interactuable?: boolean }[];
  fixLocale: string;
}>();

const localePath = useLocalePath();

const { locale, setLocale } = useI18n();

if (props.fixLocale && locale.value !== props.fixLocale) {
  setLocale(props.fixLocale);
}
</script>
