<template>
  <div id="blog-page">
    <SiteNavigationBar />
    <template v-if="validPath(false)">
      <SiteBlogResultsPage
        v-if="categorySlug"
        v-model:page="page"
        :slug="categorySlug"
        :fix-locale="locale"
        @error="onError"
      />
      <template v-else-if="postSlug">
        <SiteBlogPostPage :slug="postSlug" :fix-locale="locale" />
        <div
          v-for="carousel in carousels"
          :key="carousel.elementId"
          :data-teleport="carousel.elementId"
          style="display: none"
        >
          <HomePropertyCardsSlider
            :id="carousel.elementId + '-slider'"
            :ids="carousel.ids"
            :limit="carousel.limit"
          />
        </div>
      </template>
      <SiteBlogRoot v-else v-model:page="page" :fix-locale="locale" />
      <Transition name="modal">
        <AppModal
          v-if="showPopupForm"
          class="modal-container fix-page"
          window-class="relative max-w-[calc(100vw-16px)] max-h-[calc(100svh-32px)] min-[412px]:max-w-[480px] w-full bg-white rounded-md md:rounded-lg shadow-lg py-4 px-8 overflow-y-auto"
          @click="closePopupForm"
        >
          <AppButton
            class="icon-button variant-invisible ml-auto h-8 w-8 !absolute right-2 top-2"
            :icon="mdiClose"
            :aria-label="$t('common.closePopup')"
            @click="closePopupForm"
          />
          <div class="font-bold text-lg md:text-xl mb-4">
            {{ $t('home.popupFormTitle') }}
          </div>
          <div class="text-base mb-4">
            {{ $t('home.popupFormMessage') }}
          </div>
          <HubspotForm
            class="w-full mb-4"
            element-id="blog-popup-form"
            :form-id="{
              es: '5b909a22-7b6b-460a-8a53-df343e780120',
              en: 'c471e18b-4bee-4650-936e-7e2522b38718'
            }"
            @submitted="
              () => {
                showPopupForm = false;
                homePopupFormDone = 'true';
              }
            "
          />
        </AppModal>
      </Transition>
    </template>
    <SiteFooter v-once :fix-locale="locale" @error="logError" />
  </div>
</template>

<script setup lang="ts">
import { mdiClose } from '@mdi/js';

const SiteNavigationBar = defineAsyncComponent(
  () => import('@/async-components/Site/NavigationBar.vue')
);
const AppModal = defineAsyncComponent(
  () => import('@/async-components/App/Modal.vue')
);
const HubspotForm = defineAsyncComponent(
  () => import('@/async-components/HubspotForm.vue')
);
const HomePropertyCardsSlider = defineAsyncComponent(
  () => import('@/async-components/Home/PropertyCardsSlider.vue')
);

const route = useRoute();

const urlParams = useUrlParams();

const { fixForms } = useHubspot();

let previousParams: string[] = [];

const validPath = (useDocument = true) => {
  const path =
    (typeof window !== 'undefined' ? window : {})?.location?.pathname ||
    route.path;

  if (
    !path.startsWith(`/${tl('routes.blog', 'es')}`) &&
    !path.startsWith(`/us/${tl('routes.blog', 'us')}`) /* &&
    !path.startsWith(`/mx/${tl('routes.blog', 'mx')}`) &&
    !path.startsWith(`/co/${tl('routes.blog', 'co')}`) &&
    !path.startsWith(`/ec/${tl('routes.blog', 'ec')}`) &&
    !path.startsWith(`/pe/${tl('routes.blog', 'pe')}`) &&
    !path.startsWith(`/cl/${tl('routes.blog', 'cl')}`) */
  ) {
    return false;
  }

  if (useDocument && typeof document !== 'undefined') {
    return document.getElementById('blog-page');
  }

  return true;
};

const params = computed(() => {
  if (!validPath()) {
    return previousParams;
  }

  previousParams = urlParams.value;

  return urlParams.value;
});

const localePath = useLocalePath();

const paramsWithoutPage = computed(() => {
  return params.value.filter(
    param =>
      param &&
      !param.endsWith(`-${tl('paramsKeys.page', 'us')}`) &&
      !param.endsWith(`-${tl('paramsKeys.page', 'es')}`)
  );
});

const homePopupFormDone = useCookie('home-popup-form-done');

const router = useRouter();

const showPopupForm = ref(false);

const carousels = ref<{ ids: string[]; limit: number; elementId: string }[]>(
  []
);

const page = computed({
  get: () => {
    const pageParam = params.value.find(
      param =>
        param.endsWith(`-${tl('paramsKeys.page', 'us')}`) ||
        param.endsWith(`-${tl('paramsKeys.page', 'es')}`)
    );
    return pageParam ? parseInt(pageParam.split('-')[0]) : 1;
  },
  set: (v: number) => {
    const newPath = localePath(
      `/${t('routes.blog')}` +
        (paramsWithoutPage.value.length
          ? `/${paramsWithoutPage.value.join('/')}`
          : '') +
        (v > 1 ? `/${v}-${t('paramsKeys.page')}` : '')
    );

    if (newPath !== route.path) {
      router.push(newPath);
    }
  }
});

const { t, locale } = useI18n();

function tl(key: string, locale: string = 'us') {
  return t(key, null, { locale });
}

const categorySlug = computed(() => {
  if (
    paramsWithoutPage.value.length === 1 &&
    paramsWithoutPage.value[0].endsWith(`-${t('routes.blogSearch')}`)
  ) {
    return paramsWithoutPage.value[0];
  } else if (paramsWithoutPage.value.length <= 1) {
    return paramsWithoutPage.value[paramsWithoutPage.value.length - 1];
  } else if (paramsWithoutPage.value.length > 2 && validPath()) {
    // navigateTo(localePath(`/${t('routes.blog')}`), {
    //   replace: true,
    //   redirectCode: 301
    // });
    // showError({
    //   statusCode: 404,
    //   message: t('common.pageNotFound'),
    //   fatal: true
    // });
    // throw new Error(t('common.pageNotFound'));
  }
});

const postSlug = computed(() => {
  if (paramsWithoutPage.value.length == 2) {
    return paramsWithoutPage.value[paramsWithoutPage.value.length - 1];
  }
});

function onError(error: any) {
  throw createError({
    statusCode: error.statusCode || 500,
    message: t('common.pageNotFound')
  });
}

function updateTeleports() {
  const elements = document.querySelectorAll('[data-teleport]');

  elements.forEach(element => {
    const target = document.getElementById(
      element.getAttribute('data-teleport')
    );

    if (target) {
      target.appendChild(element);
      element.style.display = 'block';
      element.removeAttribute('data-teleport');
    }
  });
}

function updateCarousels() {
  const carouselsElements = document.querySelectorAll(
    '.content-carousel-container'
  );

  carousels.value = Array.from(carouselsElements).map((carousel, index) => {
    const idsString = carousel.getAttribute('data-ids') || '';

    if (!idsString) {
      console.error('Carousel ids not found');
      return {
        ids: [],
        limit: 0,
        elementId: ''
      };
    }

    const ids = idsString.split(',').map(id => id.trim());

    const limit = +(carousel.getAttribute('data-limit') || 0);

    return {
      ids,
      limit,
      elementId: `content-carousel-${index}-${ids[0]}`
    };
  });

  carouselsElements.forEach((carousel, index) => {
    carousel.id = carousels.value[index].elementId;
  });

  setTimeout(updateTeleports, 500);
}

function closePopupForm() {
  showPopupForm.value = false;
  homePopupFormDone.value = 'true';
}

function triggerSearch(query: string) {
  if (typeof window === 'undefined') return;

  window.location.href =
    `/${t('routes.blog')}` +
    `/${encodeURIComponent(query)}-${t('routes.blogSearch')}`;
}

function logError(error: Error) {
  console.error(error);
}

onMounted(() => {
  function triggerOnUserInteraction(callback: () => void, maxTime = 2500) {
    const userInteractionEvents = [
      'mouseover',
      'keydown',
      'touchmove',
      'touchstart'
    ];
    function triggerCallback() {
      callback();
      clearTimeout(loadScriptsTimer);
      userInteractionEvents.forEach(function (event) {
        window.removeEventListener(event, triggerCallback);
      });
    }
    userInteractionEvents.forEach(function (event) {
      window.addEventListener(event, triggerCallback, {
        passive: true
      });
    });
    const loadScriptsTimer = setTimeout(triggerCallback, maxTime);
  }

  const searchForm = document.getElementById('blog-search-form');
  const searchInput = document.getElementById('blog-search-input');

  if (searchForm && searchInput) {
    searchForm.addEventListener('submit', e => {
      e.preventDefault();
      triggerSearch((searchInput as HTMLInputElement).value);
    });
  }

  triggerOnUserInteraction(() => {
    nextTick(() => {
      updateCarousels();
      fixForms();
    });
  });
});
</script>

<style scoped lang="scss">
:deep(.search-input) {
  .input {
    .input-field {
      @apply rounded-r-none;
    }
    &:focus-within {
      .input-field {
        @apply ring-inset ring-[3px] ring-line/30;
      }
    }
  }
}
</style>
